import { isSupported } from '#/src/app/(application)/_components/NotificationWorker/api'
import { isMobile } from 'react-device-detect'
import { useMemo } from 'react'

export const isDevMode =
  process.env['NODE_ENV'] !== 'production' ||
  process.env['NEXT_PUBLIC_ALLOW_ANY_DEVICES'] === 'true'

export const checkIsStandaloneApp = () =>
  typeof window !== 'undefined' &&
  (window.matchMedia('(display-mode: standalone)').matches ||
    ('standalone' in window.navigator && window.navigator.standalone))

export const checkFullCompatibility = () => isMobile && checkIsStandaloneApp() && isSupported()

export const useCheckBrowserCompatibility = () => {
  const isStandaloneApp = useMemo(() => checkIsStandaloneApp(), [])

  const isNotificationsSupported = useMemo(() => isSupported(), [])

  const isFullyCompatible = isMobile && isStandaloneApp && isNotificationsSupported

  return {
    isFullyCompatible: isDevMode || isFullyCompatible,
    isStandaloneApp,
    isNotificationsSupported,
    isMobile
  }
}
