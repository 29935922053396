import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { configureStore } from '@reduxjs/toolkit'
import deviceLoc from '#/src/state/device-loc/slices/deviceLocSlice'
import account from '#/src/state/account/slices/accountSlice'
import notifications from '#/src/state/notifications/notificationSlice'

export const baseSplitApi = createApi({
  reducerPath: 'base',
  baseQuery: fetchBaseQuery({
    baseUrl: ``
  }),
  tagTypes: ['Auth', 'Account'],
  endpoints: () => ({})
})

export const store = configureStore({
  reducer: {
    // reducers
    [baseSplitApi.reducerPath]: baseSplitApi.reducer,
    // slices
    deviceLoc,
    account,
    notifications
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseSplitApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
