import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { DeviceLocationState } from '#/src/state/device-loc/types'
import { Place } from '#/src/app/(application)/_components/autocomplete/PlaceAutocompleteInput'
import Locations from '#/src/lib/Locations'

const initialState: DeviceLocationState = {
  locations: Locations?.locations || [],
  defaultLoc: Locations?.getDefault() ?? null
}

const slice = createSlice({
  name: 'deviceLoc',
  initialState,
  reducers: {
    setCreateLocation: (
      state,
      { payload: { isDefault, loc } }: PayloadAction<{ loc: Place; isDefault: boolean }>
    ) => {
      Locations?.createLocation(loc, isDefault)
      state.locations = Locations?.locations || state.locations
      state.defaultLoc = Locations?.getDefault() || null

      return state
    },
    setRemoveLocation: (state, { payload: { loc } }: PayloadAction<{ loc: Place }>) => {
      Locations?.removeLocation(loc)
      state.locations = Locations?.locations || state.locations
      state.defaultLoc = Locations?.getDefault() || null

      return state
    },
    setDefaultLocation: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
      Locations?.setDefault(id)
      state.locations = Locations?.locations || state.locations
      state.defaultLoc = Locations?.getDefault() || null

      return state
    }
  }
})

export const { setCreateLocation, setRemoveLocation, setDefaultLocation } = slice.actions

// available selectors - add more as needed
export const selectDeviceLocationState = (state: { deviceLoc: DeviceLocationState }) =>
  state.deviceLoc

// exports slice reducer
export default slice.reducer
