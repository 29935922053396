'use client'
import { useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { checkFullCompatibility, isDevMode } from './utils'

const BrowserBlocker = () => {
  const router = useRouter()

  useEffect(() => {
    if (isDevMode || checkFullCompatibility()) {
      return
    } else {
      alert('Please install Gourmate in your mobile to start using it')
      router.push('/')
    }
  }, [router])

  return null
}

export default BrowserBlocker
