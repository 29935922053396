import { AccountState, FEAccountDTO } from '#/src/state/account/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: AccountState = {
  currentAccount: null,
  isAppLoaded: false,
  currentUser: null
}

const slice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state, { payload: feAccount }: PayloadAction<Partial<FEAccountDTO>>) => {
      state.currentAccount = feAccount.currentAccount || state.currentAccount || null
      state.currentUser = feAccount.currentUser || state.currentUser || null
      state.isAppLoaded = true
      return state
    },
    setAccountInitialState: () => {
      return initialState
    }
  }
})

export const { setAccount, setAccountInitialState } = slice.actions

export const selectAccountState = (state: { account: AccountState }) => state.account

export default slice.reducer
