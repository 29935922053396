import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationsState } from '#/src/state/notifications/types'
import { PushNotificationPayload } from '#/src/types'

const initialState: NotificationsState = {
  newNotification: undefined
}

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addPushNotification: (
      state,
      { payload: newNotification }: PayloadAction<PushNotificationPayload>
    ) => {
      state.newNotification = newNotification
    }
  }
})

export const { addPushNotification } = slice.actions

export const selectNotificationState = (state: { notifications: NotificationsState }) =>
  state.notifications.newNotification
export const selectNewNotification = (state: { notifications: NotificationsState }) =>
  state.notifications.newNotification

export default slice.reducer
