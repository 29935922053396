'use client'
import { urlBase64ToUint8Array } from '#/src/app/(application)/_components/NotificationWorker/utils'
import { notification, PushSubscription } from '@gourmate/api'
import { PushNotificationPayload } from '#/src/types'
import { store } from '#/src/state/store'
import { addPushNotification } from '#/src/state/notifications/notificationSlice'

export const isSupported = () =>
  typeof window !== 'undefined' &&
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window

export const publicVapidKey = async () => {
  const endpoint = notification.publicKey({ init: { cache: 'no-store' } })
  const { key } = await endpoint.unwrap()
  return urlBase64ToUint8Array(key)
}

export const subscribeBackgroundNotifications = (registration: ServiceWorkerRegistration) => {
  const messageChannel = new MessageChannel()
  const serviceWorker = registration.active

  if (!serviceWorker) {
    return
  }

  serviceWorker?.postMessage(
    {
      type: 'INIT_PORT'
    },
    [messageChannel.port2]
  )

  messageChannel.port1.onmessage = (event) => {
    if (event.data?.type === 'NEW_NOTIFICATION') {
      store.dispatch(addPushNotification(event.data.payload as PushNotificationPayload))
    }
  }
}

export const subscribeToNotifications = async (registration: ServiceWorkerRegistration) => {
  if (!isSupported()) {
    console.error('Notifications are not supported in this device')
  }

  if (`${registration.scope}notifications-service-worker.js` === registration.active?.scriptURL) {
    let subscription = await registration.pushManager.getSubscription()

    if (!subscription) {
      const applicationServerKey = await publicVapidKey()
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey
      })
    }

    try {
      await notification
        .subscribe(
          { subscription: JSON.parse(JSON.stringify(subscription)) as PushSubscription },
          { init: { cache: 'no-store' } }
        )
        .unwrap()

      subscribeBackgroundNotifications(registration)
    } catch (e) {
      console.error('Not subscribed to notifications: ', e)
    }
  } else {
    throw new Error('notification-service-worker.js -- Not available')
  }
}
