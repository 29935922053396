import uniqBy from 'lodash/uniqBy'
import remove from 'lodash/remove'
import { BasePlace } from '#/src/types'

export interface LocalStoragePlace extends BasePlace {
  isDefault: boolean
}

class Locations {
  private lsKey = 'savedLocations'
  private _saved_locations: LocalStoragePlace[] = JSON.parse(
    localStorage.getItem(this.lsKey) || '[]'
  )

  constructor() {}

  get locations() {
    return this._saved_locations
  }

  save() {
    this._saved_locations = uniqBy(this._saved_locations, 'id')
    localStorage.setItem(this.lsKey, JSON.stringify(this._saved_locations))
  }

  createLocation(location: BasePlace, isDefault: boolean) {
    let updatedLocations = [...this._saved_locations]

    if (isDefault) {
      updatedLocations = updatedLocations.map((l) => ({ ...l, isDefault: false }))
    }

    updatedLocations.unshift({ ...location, isDefault })
    this._saved_locations = updatedLocations
    this.save()
  }

  removeLocation(location: BasePlace) {
    const updatedLocations = [...this._saved_locations]
    remove(updatedLocations, location)
    this._saved_locations = updatedLocations
    this.save()
  }

  getDefault() {
    const isDefaultLoc = this.locations.find((l) => l.isDefault)
    return isDefaultLoc ?? null
  }

  getById(id: string) {
    const isDefaultLoc = this.locations.find((l) => l.id === id)
    return isDefaultLoc ?? null
  }

  setDefault(id: string) {
    let updatedLocations = [...this._saved_locations]
    const requestedLocation = updatedLocations.find((l) => l.id === id)

    if (requestedLocation) {
      updatedLocations = updatedLocations.map((l) => ({ ...l, isDefault: false }))
      updatedLocations = updatedLocations.filter((l) => l.id !== requestedLocation.id)
      updatedLocations.push({ ...requestedLocation, isDefault: true })
      this._saved_locations = updatedLocations
      this.save()
    }
  }
}

const singleton = typeof window !== 'undefined' ? new Locations() : null
export default singleton
